
export function DownloadActivities(download_type){
    const auth_token = localStorage.getItem('auth_token');
    var req = new XMLHttpRequest();
    if (download_type == "all") {
        var endpoint = "/activities/download?all=true"
    } else {
        var endpoint = "/activities/download"
    }
    req.open("GET", process.env.API_URL + endpoint, true);
    req.setRequestHeader('Authorization', "Bearer " + auth_token);
    req.responseType = "blob";
    req.onload = function (event) {
        var content = req.response;
        console.log("Download request content: " + content);
        var link=document.createElement('a');
        link.href=URL.createObjectURL(content);
        link.download="activities.csv";
        link.click();
    };
    req.send();
}
