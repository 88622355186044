




























import ActivitySummary from "./ActivitySummary.vue";
import DistanceSummary from "./DistanceSummary.vue";
import {DownloadActivities} from "./js/download.js";
import {sumObj} from "./js/sumobj.js";

export default {
  props: {
    activity_summary: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      isAdmin: true
    };
  },

  computed: {
    team_summaries: function() {
      return this.activity_summary.overall_totals.filter(summary => summary.summary_key == "total:round_the_world")
    },
    challenge_distance: function() {
      var main_summary = this.team_summaries[0];
      if (main_summary) {
        return sumObj(main_summary.distance)/1000
      } else {
        return 0
      }
    },
    user_summaries: function() {
      return this.activity_summary.user_totals.filter(summary => ["round_the_world", "gap_1"].includes(summary.challenge_phase))
    }
  },

  methods:{
    download(){
      DownloadActivities('user');
    }
  },

  components: {
    ActivitySummary,
    DistanceSummary
  }
};
