





























import Vue from "vue";
import axios from "axios";

import LoggedIn from "./LoggedIn.vue";
import logoutMixin from "./js/logout.js";


export default Vue.extend({
  async created() {
    // On page load, assess whether we can log straight in...
    if (localStorage.getItem('auth_token')) {
      this.login();
    } else {
      this.screen = "login";
    }
  },

  data() {
    return {
      apiUrl: process.env.API_URL,
      screen: "loading",
      user: {}
    };
  },

  components: {
    LoggedIn
  },

  mixins: [logoutMixin],

  methods: {
    login: function() {
      console.log("Logging in with existing auth token...");
      this.screen = "loading";
      axios
        .get(
          this.apiUrl + '/user',
          { headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}
        })
        .then(response => {
          console.log("Successful GET from /user");
          this.user = response.data;
          this.screen = "loggedin";
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed GET from /user");
          console.log(error);
          this.user = null;
          this.logout();
        });
    },
    auth: function() {
      // TODO
      console.log("Authorising with Strava...");
      this.screen = "loading";
      axios
        .get(
          this.apiUrl + '/auth?stage=' + process.env.NODE_ENV,
          { headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}
        })
        .then(response => {
          console.log("Successful GET from /auth");
          if (response.data.action == 'redirect') {
              window.location.href = response.data.redirect_uri;
          } else {
              alert("Error #4: Unexpected action: " + response.data.action)
          }
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed GET from /auth");
          console.log(error);
          alert( "Auth Fail. Try Again, or tell Alan you're getting Error #3" );
          this.user = null;
          this.logout();
        });
    }
  },

});
