//https://stackoverflow.com/questions/16449295/how-to-sum-the-values-of-a-javascript-object
export function sumObj(obj) {
    var sum = 0;
    for( var el in obj ) {
        if( obj.hasOwnProperty( el ) ) {
            sum += parseFloat( obj[el] );
        }
    }
    return sum;
}
