
















































import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import axios from "axios";

import Modal from "./Modal.vue";

import {DownloadActivities} from "./js/download.js";

Vue.use(SortedTablePlugin);

export default {
  data() {
    return {
      apiUrl: process.env.API_URL,
      users: null,
      showModal: false,
      deleteUserId: null
    };
  },

  components: {
    Modal
  },

  async created() {
    this.loadUsers();
  },

  methods: {
    loadUsers() {
      console.log("Fetching /users");
      axios
        .get(
          this.apiUrl + '/users',
          { headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}
        })
        .then(response => {
          console.log("Successful GET from /users");
          this.users = response.data.users;
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed GET from /users");
          console.log(error.message);
        });
    },
    loadActivities (user_id) {
      console.log("Updating activities for " + user_id);
      this.users = null;
      axios
        .get(
          this.apiUrl + '/activities/summary',
          {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')},
            params: {
              user_id: user_id,
              force_resync: true
            }
          }
        )
        .then(response => {
          console.log("Successful GET from /activities/summary");
          // alert("Updated user id " + user_id);
          this.$emit('refreshLoggedIn');
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed GET from /activities/summary");
          console.log(error);
          this.loadUsers();
        });
    },
    deleteUser (user_id) {
      console.log("Deleting user " + user_id);
      this.users = null;
      this.deleteUserId = null;
      this.showModal = false;
      axios
        .delete(
          this.apiUrl + '/user/' + user_id,
          {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')},
          }
        )
        .then(response => {
          console.log("Successful DELETE from /user");
          // alert("Updated user id " + user_id);
          this.$emit('refreshLoggedIn');
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed DELETE from /user");
          console.log(error);
          this.loadUsers();
        });
    },
    deleteModal (user_id) {
      this.deleteUserId = user_id;
      this.showModal = true;
    },
    downloadAll(){
      DownloadActivities('all');
    }
  }
};
