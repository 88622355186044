// define a logout mixin object
export default {
  methods: {
    logout: function () {
      console.log("Logging Out.");
      localStorage.clear();
      window.location.href = "/index.html";
    },
  }
}
