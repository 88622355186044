










































import {distances, challenge_end} from "./js/distances.js";

export default {
  props: {
    distance: {
      required: true,
      type: Number
    }
  },

  computed: {
    last_checkpoint: function() {
      var i = 0;
      while(i < distances.length) {
        if (distances[i].km > this.distance) {
          return distances[i - 1];
        }
        i++;
      }
      return distances[i - 1]
    },
    next_checkpoint: function() {
      var i = 0;
      while(i < distances.length) {
        if (distances[i].km > this.distance) {
          return distances[i];
        }
        i++;
      }
      return null
    },
    next_p1_checkpoint: function() {
      var i = 0;
      while(i < distances.length) {
        if (distances[i].km > this.distance) {
          return distances[i + 1];
        }
        i++;
      }
      return null
    },
    last_m1_checkpoint: function() {
      var i = 0;
      while(i < distances.length) {
        if (distances[i].km > this.distance) {
          return distances[i - 2];
        }
        i++;
      }
      return null
    },
    inter_checkpoint_percentage: function(){
      if (this.next_checkpoint) {
        return (100 * (this.distance - this.last_checkpoint.km) / (this.next_checkpoint.km - this.last_checkpoint.km)).toFixed(1)
      } else {
        return '100'
      }
    },
    challenge_done: function(){
      return this.distance >= challenge_end
    },
    total_percentage: function(){
      return (100 * this.distance / challenge_end).toFixed(1)
    }
  }
};
