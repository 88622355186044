













































import Vue from "vue";
import axios from "axios";

import Activities from "./Activities.vue";
import Admin from './Admin.vue';
import logoutMixin from "./js/logout.js";


export default Vue.extend({
  props: {
    user: {
      required: true,
      type: Object
    }
  },  

  data() {
    return {
      apiUrl: process.env.API_URL,
      activity_summary: null,
      allow_team_choice: false,
      error_message: null
    };
  },

  computed: {
    teamBadgeClass() {
      if ( this.user.team_name == 'jolly') {
        return ["badge-danger"]
      } else if ( this.user.team_name == 'tiers') {
        return ["badge-info"]
      } else {
        return ["badge-secondary"]
      }
    }
  },

  mixins: [logoutMixin],

  async created() {
    if (!this.user.team_name) {
      this.pickTeam("default");
    }
    this.loadActivities();
  },

  methods: {
    loadActivities (force_resync = false, user_id = null) {
      console.log("Loading activities... ");
      // Work out what kind of sync we're doing.
      var data_payload = {};
      if (force_resync) {
        data_payload["force_resync"] = true;
      }
      if (user_id) {
        data_payload["user_id"] = user_id;
        alert("Updating user id " + user_id);
      } else {
        // If it's for this user, blank the screen
        this.activity_summary = null
      }
      axios
        .get(
          this.apiUrl + '/activities/summary',
          {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')},
            params: data_payload
          }
        )
        .then(response => {
          console.log("Successful GET from /activities/summary");
          if (user_id) {
            alert("Updated user id " + user_id);
          } else {
            this.activity_summary = response.data;
          }
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed GET from /activities/summary");
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.status == 429) {
              console.log("Rate Limited.");
              this.error_message = "Rate limited by Strava. People have been too active. Try the app again tomorrow.";
            } else {
              console.log("Other...");
              this.error_message = "Unexpected Error. Please report this.";
            }
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
    pickTeams() {
      this.user.team_name = null;
    },
    pickTeam(team_name) {
      console.log("Picking " + team_name);
      this.user.team_name = team_name;
      this.activity_summary = null;
      axios({
        method: 'post',
        url: this.apiUrl + '/user?team_name=' + team_name,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}
      }).then(response => {
          console.log("Successful POST to /user");
          this.loadActivities();
        })
        .catch(error => {
          // Wu oh! Something went wrong
          console.log("Failed POST to /user");
          console.log(error);
          this.logout();
        });
    }
  },

  components: {
    Activities,
    Admin
  }
});
