


















































import {sumObj} from "./js/sumobj.js";

export default {
  props: {
    summary: {
      required: true,
      type: Object
    }
  },
  methods: {
    sum_obj: function (obj) {
      return sumObj(obj);
    }
  },
  computed: {
    title: function() {
      if (this.summary.challenge_phase == 'gap_1') {
        return "What you've done since the last challenge..."
      } else if (this.summary.challenge_phase == 'round_the_world') {
        if (this.summary.summary_type == "user:phase") {
          return "Around the World! 🌍"
        } else {
          return "Everyone's distance around the World! 🌍"
        }
      } else {
        return this.summary.summary_key
      }
    },
    styles: function() {
      if (this.summary.summary_type == 'total:phase') {
        return ["text-white", "bg-warning"]
      } else {
        return ["bg-light"]
      }
    }
  },
  data() {
    return {
      foo: "bar",
      isLoaded: true
    };
  },
};
