// Defines the distances in the tour.
// The km distance is the distance UP TO this point.

export const distances = [
    {
        "city": "London",
        "km": 0,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/London",
    },
    {
        "city": "Folkstone",
        "km": 113,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Folkestone",
    },
    {
        "city": "Calais",
        "km": 113,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Calais",
    },
    {
        "city": "Paris",
        "km": 378,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Paris",
    },
    {
        "city": "Stuttgart",
        "km": 981,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Stuttgart",
    },
    {
        "city": "Munich",
        "km": 1195,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Munich",
    },
    {
        "city": "Salzburg",
        "km": 1319,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Salzburg",
    },
    {
        "city": "Ljubljana",
        "km": 1576,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Ljubljana",
    },
    {
        "city": "Zagreb ",
        "km": 1688,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Zagreb",
    },
    {
        "city": "Belgrade",
        "km": 2044,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Belgrade",
    },
    {
        "city": "Skopje",
        "km": 2436,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Skopje",
    },
    {
        "city": "Thessaloniki",
        "km": 2633,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Thessaloniki",
    },
    {
        "city": "Athens",
        "km": 3081,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Athens",
    },
    {
        "city": "Fethiye",
        "km": 3081,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Fethiye",
    },
    {
        "city": "Antalya",
        "km": 3279,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Antalya",
    },
    {
        "city": "Silifke",
        "km": 3646,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Silifke",
    },
    {
        "city": "Tripoli (Lebanon)",
        "km": 3646,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Tripoli,_Lebanon",
    },
    {
        "city": "Tel Aviv",
        "km": 4095,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Tel_Aviv",
    },
    {
        "city": "Suez",
        "km": 4661,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Suez",
    },
    {
        "city": "Jeddah",
        "km": 4661,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Jeddah",
    },
    {
        "city": "Riyadh",
        "km": 5874,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Riyadh",
    },
    {
        "city": "Doha",
        "km": 6455,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Doha",
    },
    {
        "city": "Dubai",
        "km": 7109,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Dubai",
    },
    {
        "city": "Bandar Abbas",
        "km": 7109,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Bandar_Abbas",
    },
    {
        "city": "Gwadar",
        "km": 7958,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Gwadar",
    },
    {
        "city": "Karachi",
        "km": 8576,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Karachi",
    },
    {
        "city": "Ahmedabad",
        "km": 9418,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Ahmedabad",
    },
    {
        "city": "Mumbai",
        "km": 9921,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Mumbai",
    },
    {
        "city": "Pune",
        "km": 10077,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Pune",
    },
    {
        "city": "Chennai",
        "km": 11266,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Chennai",
    },
    {
        "city": "Hong Kong",
        "km": 11266,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Hong_Kong",
    },
    {
        "city": "Cheung Chau",
        "km": 11286,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Cheung_Chau",
    },
    {
        "city": "Guangzhou",
        "km": 11420,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Guangzhou",
    },
    {
        "city": "Yingtan",
        "km": 12251,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Yingtan",
    },
    {
        "city": "Hangzhou",
        "km": 12687,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Hangzhou",
    },
    {
        "city": "Shanghai",
        "km": 12838,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Shanghai",
    },
    {
        "city": "Yokohama",
        "km": 12838,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Yokohama",
    },
    {
        "city": "Tokyo",
        "km": 12870,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Tokyo",
    },
    {
        "city": "Los Angeles",
        "km": 12870,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Los_Angeles",
    },
    {
        "city": "Las Vegas",
        "km": 13305,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Las_Vegas",
    },
    {
        "city": "Aspen",
        "km": 14399,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Aspen,_Colorado",
    },
    {
        "city": "Denver",
        "km": 14718,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Denver",
    },
    {
        "city": "Omaha (Nebraska)",
        "km": 15588,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Omaha,_Nebraska",
    },
    {
        "city": "Des Moines (Iowa)",
        "km": 15804,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Des_Moines,_Iowa",
    },
    {
        "city": "Chicago",
        "km": 16304,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Chicago",
    },
    {
        "city": "Cleveland (Ohio)",
        "km": 16860,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/Cleveland",
    },
    {
        "city": "New York",
        "km": 17592,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/New_York_City",
    },
    {
        "city": "Felixstowe",
        "km": 17592,
        "ship": true,
        "url": "https://en.wikipedia.org/wiki/Felixstowe",
    },
    {
        "city": "London",
        "km": 17722,
        "ship": false,
        "url": "https://en.wikipedia.org/wiki/London",
    },
]

export const challenge_end = distances[distances.length - 1].km
