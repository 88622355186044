














import Vue from "vue";

export default Vue.extend({
  props: {
    showModal: {
      required: true,
      type: Boolean
    }
  }, 
});
